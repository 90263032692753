.toast-header{
    font-weight: 550;
    font-size: 15px;
}
.toast-icon{
    color:#85e0a3 ;
}
.Toastify__toast-body{
    align-items: start!important;
}
.toast-content {
font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

}
.emoji-hand{
    font-size: 22px;
}