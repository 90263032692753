.container {
  height: 60px;
  /* width: 300px; */
}

.character {
  line-height: 50px;
  font-size: 33px;
  border: 0.1rem solid rgba(210, 213, 218, 1);
  border-radius: 8px;
  margin-left: 8px;
  color: #888 !important;
  padding-top: 4px;
  text-transform: uppercase;
}

.character:first-child {
  margin-left: 0;
}

.character--inactive {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0.1rem solid rgba(210, 213, 218, 1);
}

.character--selected {
  border: 0.1rem solid #000;
}
