body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f4f6;
}

@keyframes fadeOutUp {
  0% {
     opacity: 1;
     transform: translateY(-50%);
  }
  100% {
     opacity: 0;
     transform: translateY(-100%);
  }
}

.frame__copied-alert {
    animation: fadeOutUp 1s ease-out;
}


