.color-palette-backdrop {
  background-color: rgba($color: #000000, $alpha: .2);
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 1;
  left: 0;
  top: 0;
  pointer-events: none;
}

.color-palette {
  transform: translate(-50%, -50%);
  width: unset;
  left: 50%;
  margin: 0;
  top: 50%;
}

.color-button{
  outline: 1px solid silver;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 26px;
  width: 26px;

  &::after{
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    left: 0;
    top: 0;
  }

  &:hover{
    &::after{
      background-color: rgba($color: #000000, $alpha: .1);
    }
  }

  &:active{
    &::after{
      background-color: rgba($color: #000000, $alpha: .2);
    }
  }
}