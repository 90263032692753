@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&family=Source+Code+Pro&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.error-box {
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin: 20px auto;
  max-width: 400px;
  text-align: center;
}

/* Styles for ipod pro */

.kiosk-font-2xl {
  line-height: 2.4rem;
}

@media only screen and (min-width: 1024px) {
  .kiosk-font-2xl {
    font-size: 1.875rem;
  }
  .kiosk-font-xl {
    font-size: 1.5rem;
  }
  .kiosk-font-lg {
    font-size: 1.25rem;
  }
  .kiosk-table-image {
    width: 6.875rem;
    height: 6.875rem;
  }
  .kiosk-frame-selector-box {
    width: 35.0625rem;
    /* height: 7rem; */
  }
  .kiosk-frame-box-icon {
    width: 3rem;
    height: 3rem;
  }
  .kiosk-frame-image {
    width: 5rem;
    height: 5rem;
  }
  .kiosk-header {
    height: 9rem;
  }
  .kiosk-search-input {
    height: 4.375rem;
  }
  .kiosk-push-button {
    width: 4rem;
    height: 4rem;
  }
  .kiosk-push-button-icon {
    width: 3rem;
    height: 3rem;
  }
  .kiosk-dropdown {
    height: 4rem;
    padding: 0.8rem;
  }
  .kiosk-dropdown-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 0.2rem;
  }
  .kiosk-logo-img {
    height: 1.75rem;
  }
  .kiosk-logo-text {
    height: 1.75rem;
  }
}

/* Styles for iPod Mini */

@media only screen and (min-width: 568px) and (max-width: 768px) {
  .kiosk-font-2xl {
    font-size: 1.35rem;
  }
  .kiosk-font-xl {
    font-size: 1.15rem;
  }
  .kiosk-font-lg {
    font-size: 1rem;
  }
  .kiosk-table-image {
    width: 6rem;
    height: 6rem;
  }
  .kiosk-frame-selector-box {
    width: 22.25rem;
    /* height: 6rem; */
  }
  .kiosk-frame-box-icon {
    width: 2rem;
    height: 2rem;
  }
  .kiosk-frame-image {
    width: 4rem;
    height: 4rem;
  }
  .kiosk-header {
    height: 8rem;
  }
  .kiosk-search-input {
    height: 3.5rem;
  }
  .kiosk-push-button {
    width: 3rem;
    height: 3rem;
  }
  .kiosk-push-button-icon {
    width: 3rem;
    height: 3rem;
  }
  .kiosk-dropdown {
    height: 3rem;
    padding: 0.59rem;
  }
  .kiosk-dropdown-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.2rem;
  }
}
/* Styles for iPod Air */
@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .kiosk-font-2xl {
    font-size: 1.75rem;
  }
  .kiosk-font-xl {
    font-size: 1.5rem;
  }
  .kiosk-font-lg {
    font-size: 1.25rem;
  }
  .kiosk-table-image {
    width: 6rem;
    height: 6rem;
  }
  .kiosk-frame-selector-box {
    width: 21.875rem;
    /* height: 6.75rem; */
  }
  .kiosk-frame-box-icon {
    width: 3rem;
    height: 3rem;
  }
  .kiosk-frame-image {
    width: 5rem;
    height: 5rem;
  }
  .kiosk-header {
    height: 9rem;
  }
  .kiosk-search-input {
    height: 4.375rem;
  }
  .kiosk-push-button {
    width: 4rem;
    height: 4rem;
  }
  .kiosk-push-button-icon {
    width: 3rem;
    height: 3rem;
  }
  .kiosk-dropdown {
    height: 4rem;
    padding: 0.8rem;
  }
  .kiosk-dropdown-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 0.2rem;
  }
}

/* Styles for mobile devices */
@media only screen and (max-width: 567px)  {
  .kiosk-font-2xl {
    font-size: 0.95rem;
  }
  .kiosk-font-xl {
    font-size: 0.85rem;
  }
  .kiosk-font-lg {
    font-size: 0.75rem;
  }
  .kiosk-table-image {
    width: 4.25rem;
    height: 4.25rem;
  }
  .kiosk-frame-selector-box {
    width: 13rem;
    height: 4rem;
    padding: 0 10px 0 10px;
  }
  .kiosk-frame-box-icon {
    width: 1rem;
    height: 1rem;
  }
  .kiosk-frame-image {
    width: 3rem;
    height: 3rem;
  }
  .kiosk-header {
    height: 5rem;
  }
  .kiosk-search-input {
    height: 2.375rem;
  }
  .kiosk-push-button-icon {
    width: 2rem;
    height: 1.5rem;
  }
  .kiosk-dropdown {
    height: 2.5rem;
    padding: 0.5rem;
  }
  .kiosk-dropdown-icon {
    width: 1rem;
    height: 1.5rem;
  }
  .kiosk-logo-img {
    height: 0.75rem;
  }
  .kiosk-logo-text {
    height: 0.75rem;
  }
}
